<template>
  <div class="card p-4">
    <div class="row">
      <div class="col-lg-3 mb-7">
        <router-link to="/order">
          <div class="bg-light-danger px-6 py-8 rounded-sm">
            <div class="d-flex align-items-center border-0">
              <div class="card-title align-items-start flex-column mb-0">
                <span class="symbol symbol-50 symbol-danger mr-2">
                  <span class="symbol-label">
                    <i class="menu-icon flaticon2-shopping-cart text-light"></i>
                  </span>
                </span>
              </div>
              <div class="card-toolbar ml-auto">
                <div class="text-danger font-weight-bold font-size-h6 mt-2 text-right">
                  Orders
                </div>
                <span class="text-dark text-right mt-3 font-weight-bold font-size-sm">
                  0 Orders
                </span>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-lg-3 mb-7">
        <router-link to="/return">
          <div class="bg-light-danger px-6 py-8 rounded-sm">
            <div class="d-flex align-items-center border-0">
              <div class="card-title align-items-start flex-column mb-0">
                <span class="symbol symbol-50 symbol-danger mr-2">
                  <span class="symbol-label">
                    <i class="menu-icon flaticon2-shopping-cart text-light"></i>
                  </span>
                </span>
              </div>
              <div class="card-toolbar ml-auto">
                <div class="text-danger font-weight-bold font-size-h6 mt-2 text-right">
                  Returns
                </div>
                <span class="text-dark text-right mt-3 font-weight-bold font-size-sm">
                  0 return
                </span>
              </div>
            </div>
          </div>
        </router-link>
      </div>

      <div class="col-lg-3 mb-7">
        <router-link to="/cancellation">
          <div class="bg-light-danger px-6 py-8 rounded-sm">
            <div class="d-flex align-items-center border-0">
              <div class="card-title align-items-start flex-column mb-0">
                <span class="symbol symbol-50 symbol-danger mr-2">
                  <span class="symbol-label">
                    <i class="menu-icon flaticon2-shopping-cart text-light"></i>
                  </span>
                </span>
              </div>
              <div class="card-toolbar ml-auto">
                <div class="text-danger font-weight-bold font-size-h6 mt-2 text-right">
                  Cancellations
                </div>
                <span class="text-dark text-right mt-3 font-weight-bold font-size-sm">
                  0 cancellations
                </span>
              </div>
            </div>
          </div>
        </router-link>
      </div>

      <div class="col-lg-3 mb-7">
        <router-link to="/review">
          <div class="bg-light-danger px-6 py-8 rounded-sm">
            <div class="d-flex align-items-center border-0">
              <div class="card-title align-items-start flex-column mb-0">
                <span class="symbol symbol-50 symbol-danger mr-2">
                  <span class="symbol-label">
                    <i class="menu-icon flaticon2-shopping-cart text-light"></i>
                  </span>
                </span>
              </div>
              <div class="card-toolbar ml-auto">
                <div class="text-danger font-weight-bold font-size-h6 mt-2 text-right">
                  Review
                </div>
                <span class="text-dark text-right mt-3 font-weight-bold font-size-sm">
                  0 Orders
                </span>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-lg-3 mb-7">
        <router-link to="/products">
          <div class="bg-light-warning px-6 py-8 rounded-sm">
            <div class="d-flex align-items-center border-0">
              <div class="card-title align-items-start flex-column mb-0">
                <span class="symbol symbol-50 symbol-warning mr-2">
                  <span class="symbol-label">
                    <i class="menu-icon flaticon2-box-1 text-light"></i>
                  </span>
                </span>
              </div>
              <div class="card-toolbar ml-auto">
                <div class="text-warning font-weight-bold font-size-h6 mt-2 text-right">
                  Products
                </div>
                <span class="text-dark text-right mt-3 font-weight-bold font-size-sm">
                  1200 Products
                </span>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-lg-3 mb-7">
        <router-link to="/users">
          <div class="bg-light-success px-6 py-8 rounded-sm">
            <div class="d-flex align-items-center border-0">
              <div class="card-title align-items-start flex-column mb-0">
                <span class="symbol symbol-50 symbol-success mr-2">
                  <span class="symbol-label">
                    <i class="menu-icon flaticon-users text-light"></i>
                  </span>
                </span>
              </div>
              <div class="card-toolbar ml-auto">
                <div class="text-success font-weight-bold font-size-h6 mt-2 text-right">
                  New users
                </div>
                <span class="text-dark text-right mt-3 font-weight-bold font-size-sm">
                  13000 Users
                </span>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-lg-3 mb-7">
        <router-link to="/contact">
          <div class="bg-light-success px-6 py-8 rounded-sm">
            <div class="d-flex align-items-center border-0">
              <div class="card-title align-items-start flex-column mb-0">
                <span class="symbol symbol-50 symbol-success mr-2">
                  <span class="symbol-label">
                    <i class="menu-icon flaticon-users text-light"></i>
                  </span>
                </span>
              </div>
              <div class="card-toolbar ml-auto">
                <div class="text-success font-weight-bold font-size-h6 mt-2 text-right">
                  Contacts
                </div>
                <span class="text-dark text-right mt-3 font-weight-bold font-size-sm">
                  10 Contacts
                </span>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "dashboard",
  components: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  },
  methods: {},
};
</script>
